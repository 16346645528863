import { FC } from "react";
import Image from "next/image";
import Link from "next/link";
import { ChevronDown } from "components/atoms/Icon";
import { Span } from "utils/styling/typography.styled";
import * as Styled from "./Navigation.styled";
const Navigation: FC = () => {
  return <Styled.StyledNav data-sentry-element="unknown" data-sentry-component="Navigation" data-sentry-source-file="index.tsx">
            <Link title="Home" href="/" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Home</Span>
            </Link>
            <Link title="Pricing" href="/pricing" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Pricing</Span>
            </Link>
            <Link title="Session Companion" href="/session-companion" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Session Companion</Span>
            </Link>
            <Styled.GenerateLinks data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Link title="Battlemap Generator" href="/battlemap" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                    <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Battlemap</Span>
                </Link>
                <Link title="Character Generator" href="/character" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                    <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Character</Span>
                </Link>
                <Link title="Item Generator" href="/item" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                    <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Item</Span>
                </Link>
                <Link title="Landscape Generator" href="/landscape" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                    <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Landscape</Span>
                </Link>
                <Link title="Monster Generator" href="/monster" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                    <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Monster</Span>
                </Link>
                <Link title="NPC Generator" href="/npc" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                    <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">NPC</Span>
                </Link>
            </Styled.GenerateLinks>
            <Styled.Dropdown data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Styled.DropdownTrigger data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Generate</Span>
                    <Image src={ChevronDown} width={16} height={16} alt="Chevron Down Icon" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                </Styled.DropdownTrigger>
                <Styled.DropdownContent data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Link title="Battlemap Generator" href="/battlemap" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Battlemap</Span>
                    </Link>
                    <Link title="Character Generator" href="/character" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Character</Span>
                    </Link>
                    <Link title="Item Generator" href="/item" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Item</Span>
                    </Link>
                    <Link title="Landscape Generator" href="/landscape" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Landscape</Span>
                    </Link>
                    <Link title="Monster Generator" href="/monster" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Monster</Span>
                    </Link>
                    <Link title="NPC Generator" href="/npc" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">NPC</Span>
                    </Link>
                </Styled.DropdownContent>
            </Styled.Dropdown>
            <Styled.GenerateLinks data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Link title="My Gallery" href="/my-gallery" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                    <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">My Gallery</Span>
                </Link>
                <Link title="Public Gallery" href="/gallery" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                    <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Public Gallery</Span>
                </Link>
            </Styled.GenerateLinks>
            <Styled.Dropdown data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Styled.DropdownTrigger data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Gallery</Span>
                    <Image src={ChevronDown} width={16} height={16} alt="Chevron Down Icon" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                </Styled.DropdownTrigger>
                <Styled.DropdownContent data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Link title="My Gallery" href="/my-gallery" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">My Gallery</Span>
                    </Link>
                    <Link title="Public Gallery" href="/gallery" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Public Gallery</Span>
                    </Link>
                </Styled.DropdownContent>
            </Styled.Dropdown>
            <Styled.GenerateLinks data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Link title="CharGen Leaderboards" href="/leaderboards" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                    <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Leaderboards</Span>
                </Link>
                <Link title="CharGen Competitions" href="/competitions" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                    <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Competitions</Span>
                </Link>
            </Styled.GenerateLinks>
            <Styled.Dropdown data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <Styled.DropdownTrigger data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Competitions</Span>
                    <Image src={ChevronDown} width={16} height={16} alt="Chevron Down Icon" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                </Styled.DropdownTrigger>
                <Styled.DropdownContent data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Link title="CharGen Leaderboards" href="/leaderboards" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Leaderboards</Span>
                    </Link>
                    <Link title="CharGen Competitions" href="/competitions" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <Span data-sentry-element="Span" data-sentry-source-file="index.tsx">Competitions</Span>
                    </Link>
                </Styled.DropdownContent>
            </Styled.Dropdown>
        </Styled.StyledNav>;
};
export default Navigation;