import { Auth } from "@aws-amplify/auth";
import { AppProps } from "next/app";
import { Nunito } from "next/font/google";
import Head from "next/head";
import { GoogleAnalytics } from "nextjs-google-analytics";
import LayoutWrapper from "components/organisms/LayoutWrapper";
import { GlobalStyles } from "utils/styling/globalStyles.styled";
const nunito = Nunito({
  weight: ["400", "600", "700"],
  display: "swap",
  subsets: ["latin-ext"]
});
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}
Auth.configure({
  region: "eu-west-2",
  userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_WEB_CLIENT,
  oauth: {
    domain: process.env.NEXT_PUBLIC_COGNITO_AUTH_DOMAIN,
    scope: ["email", "openid", "profile"],
    redirectSignIn: process.env.NEXT_PUBLIC_COGNITO_REDIRECT,
    redirectSignOut: process.env.NEXT_PUBLIC_COGNITO_REDIRECT,
    responseType: "code"
  }
});
function MyApp({
  Component,
  pageProps
}: AppProps) {
  return <>
            <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
                <meta name="description" content="Create stunning AI-generated art for your D&D characters in seconds with CharGen. Start crafting unique RPG character images for free today!" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
                <title>CharGen: AI Generated D&D Character Art</title>
                <meta property="og:url" key="og:url" content="https://char-gen.com" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
                <meta property="og:description" key="og:description" content="Create stunning AI-generated art for your D&D characters in seconds with CharGen. Start crafting unique RPG character images for free today!" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
                <meta property="og:type" content="website" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
                <meta property="og:title" key="og:title" content="CharGen: AI Generated D&D Character Art | Best Fantasy AI Art and DnD Character Creator" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
                <meta property="og:image:url" key="og:image:url" content="https://charsheet-gen.s3.eu-west-2.amazonaws.com/fantasy-ai-character-generator.png" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
                <meta property="og:image" key="og:image" content="https://charsheet-gen.s3.eu-west-2.amazonaws.com/fantasy-ai-character-generator.png" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
                <meta property="og:image:secure_url" key="og:image:secure_url" content="https://charsheet-gen.s3.eu-west-2.amazonaws.com/fantasy-ai-character-generator.png" data-sentry-element="meta" data-sentry-source-file="_app.tsx" />
            </Head>
            <GoogleAnalytics gaMeasurementId="G-5R8RL76MN1" data-sentry-element="GoogleAnalytics" data-sentry-source-file="_app.tsx" />
            <GlobalStyles data-sentry-element="GlobalStyles" data-sentry-source-file="_app.tsx" />
            <div id="root" className={nunito.className} style={{
      backgroundColor: "#1f2136"
    }}>
                <LayoutWrapper data-sentry-element="LayoutWrapper" data-sentry-source-file="_app.tsx">
                    <Component {...pageProps} data-sentry-element="Component" data-sentry-source-file="_app.tsx" />
                </LayoutWrapper>
            </div>
        </>;
}
export default MyApp;