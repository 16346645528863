import { styled } from "styled-components";

import { Button as StyledButton } from "components/atoms/Button";
import { device } from "utils/sizes";
import { colourScheme } from "utils/styling/colours";
import { Small } from "utils/styling/typography.styled";

export const Container = styled.div`
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    .span {
        white-space: normal; // Allow text to wrap
        word-wrap: break-word; // Break words if necessary
    }
`;

export const Button = styled(StyledButton)`
    ${device.sm} {
        padding: 0.3rem 0.5rem; // Adjusted padding for small screens
    }

    ${device.md} {
        padding: 0.5rem 0.5rem; // Default padding for medium screens
    }

    ${device.xl} {
        padding: 0.75rem 1rem;
    }
`;

export const NewLabel = styled(Small)`
    vertical-align: top;
    font-size: 0.5rem !important;
    color: ${colourScheme.alert};

    ${device.xl} {
        font-size: 0.75rem !important;
    }
`;
