import styled from "styled-components";

import { colourScheme, hexToRgba } from "utils/styling/colours";
import { Text as StyledText } from "utils/styling/typography.styled";

export const NotificationItem = styled.div`
    padding: 0.5rem;
    border-bottom: 1px solid ${colourScheme.primary};
    &:last-child {
        border-bottom: none;
    }
    cursor: pointer;
    display: flex;
    flex-direction: column;

    transition:
        transform 0.1s ease,
        background-color 0.1s ease;

    &:hover {
        background-color: ${hexToRgba(colourScheme.primaryLight, 0.6)};
        transform: scale(1.01); // Slightly enlarge on hover
    }
    border-radius: 0.5rem;
`;

export const NotificationMessage = styled.div`
    color: ${colourScheme.white};
    flex: 1;
`;

export const Text = styled(StyledText)`
    font-weight: regular;
    font-size: 1rem;
`;

export const NotificationTimestamp = styled.div`
    color: ${colourScheme.secondary};
    font-size: 0.8rem;
    white-space: nowrap;
`;

export const IconButton = styled.div`
    cursor: pointer;
    background: none;
    border: none;
    position: relative;
    cursor: pointer;
    padding: 0;
    margin: 0 0.5rem;
    &:focus {
        outline: none;
    }
`;

export const NotificationCount = styled.span`
    position: absolute;
    top: -4px;
    right: -4px;
    background-color: red;
    color: white;
    border-radius: 100%;
    padding: 0 0.25rem;
    font-size: 0.625rem !important;
    font-weight: bold;
`;

export const NoNotifications = styled.div`
    padding: 0.5rem;
    text-align: center;
    color: ${colourScheme.secondary};
`;

export const NotificationWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
`;
