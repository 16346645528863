import { createContext, FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import Image from "next/image";
import { ChevronLeft, ChevronRight, Cross } from "components/atoms/Icon";
import { AppContext } from "lib/contexts";
import { AppReducerActionType } from "types";
import * as Styled from "./Modal.styled";
type ModalProps = {
  children: React.ReactNode;
  open: boolean;
  handleBackdropClick: () => void;
  disableBackdropClick?: boolean;
  controls?: boolean;
  moveBack?: () => void;
  moveForward?: () => void;
};
export const ModalContext = createContext<HTMLDivElement | null>(null);
export const Modal: FC<ModalProps> = ({
  children,
  open,
  handleBackdropClick,
  disableBackdropClick = false,
  controls = false,
  moveBack,
  moveForward
}) => {
  const {
    state,
    dispatch
  } = useContext(AppContext);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [modalNode, setModalNode] = useState<HTMLDivElement | null>(null);
  const backdropClick = useCallback(() => {
    if (!disableBackdropClick) {
      handleBackdropClick();
    }
  }, [disableBackdropClick, handleBackdropClick]);
  const handleModalRef = (node: HTMLDivElement | null) => {
    modalRef.current = node;
    setModalNode(node);
  };
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!modalRef.current || !open) return;
      switch (event.key) {
        case "Escape":
          backdropClick();
          break;
        case "ArrowRight":
          event.stopPropagation();
          moveForward?.();
          break;
        case "ArrowLeft":
          event.stopPropagation();
          moveBack?.();
          break;
      }
    };
    if (open) {
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [open, backdropClick, moveBack, moveForward]);
  useEffect(() => {
    dispatch({
      type: AppReducerActionType.TOGGLE_OVERLAY_VISIBILITY,
      payload: {
        ...state,
        isOverlayVisible: open
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  return <ModalContext.Provider value={modalNode} data-sentry-element="unknown" data-sentry-component="Modal" data-sentry-source-file="index.tsx">
            <Styled.ModalOverlay ref={handleModalRef} onClick={backdropClick} $open={open} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                {controls && <Styled.ControlIconWrapper onClick={e => {
        e.stopPropagation();
        moveBack?.();
      }}>
                        <Image priority src={ChevronLeft} alt="Previous Image" height={24} width={24} />
                    </Styled.ControlIconWrapper>}
                <Styled.ModalContainer onClick={e => e.stopPropagation()} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    <Styled.ModalHeader data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                        <Styled.ModalIcon onClick={handleBackdropClick} data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                            <Image priority src={Cross} height={40} width={40} alt="Close modal" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
                        </Styled.ModalIcon>
                    </Styled.ModalHeader>
                    {children}
                </Styled.ModalContainer>
                {controls && <Styled.ControlIconWrapper onClick={e => {
        e.stopPropagation();
        moveForward?.();
      }}>
                        <Image priority src={ChevronRight} alt="Next Image" height={24} width={24} />
                    </Styled.ControlIconWrapper>}
            </Styled.ModalOverlay>
        </ModalContext.Provider>;
};