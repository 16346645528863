import { AppReducerAction, AppReducerActionType, AppReducerState } from "types";

export const appReducer = (
    state: AppReducerState,
    action: AppReducerAction
) => {
    switch (action.type) {
        case AppReducerActionType.SHOW_PRICING_TABLE:
            return {
                ...state,
                showPricingTable: action.payload.showPricingTable,
            };
        case AppReducerActionType.DEC_GOLD:
            return {
                ...state,
                gold: --state.gold,
            };
        case AppReducerActionType.SET_GOLD:
            return {
                ...state,
                gold: action.payload.gold,
            };
        case AppReducerActionType.SET_COOKIE_CONSENT:
            return {
                ...state,
                cookieConsentGiven: action.payload.cookieConsentGiven,
            };
        case AppReducerActionType.SET_LOGGED_IN:
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn,
            };
        case AppReducerActionType.SHOW_AUTH_MODAL:
            return {
                ...state,
                showAuthModal: action.payload.showAuthModal,
            };
        case AppReducerActionType.SET_USER_PREMIUM_LEVEL:
            return {
                ...state,
                userPremiumLevel: action.payload.userPremiumLevel,
            };
        case AppReducerActionType.SET_USER_FREE_GENS:
            return {
                ...state,
                userFreeGens: action.payload.userFreeGens,
            };
        case AppReducerActionType.SHOW_SUBSCRIPTION_TABLE:
            return {
                ...state,
                showSubscriptionTable: action.payload.showSubscriptionTable,
            };
        case AppReducerActionType.TOGGLE_OVERLAY_VISIBILITY:
            return {
                ...state,
                isOverlayVisible: action.payload.isOverlayVisible,
            };
        case AppReducerActionType.SET_USERNAME:
            return {
                ...state,
                username: action.payload.username,
            };
        case AppReducerActionType.SET_REFERRAL_CODE:
            return {
                ...state,
                referralCode: action.payload.referralCode,
            };
        case AppReducerActionType.SET_GCLID:
            return {
                ...state,
                gclid: action.payload.gclid,
            };
        case AppReducerActionType.SET_DAILY_LOGIN_BONUS:
            return {
                ...state,
                dailyLoginBonus: action.payload.dailyLoginBonus,
            };
        default:
            return state;
    }
};
