"use client";

import { useEffect, useReducer, useState } from "react";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import * as Sentry from "@sentry/react";
declare global {
  interface Window {
    Tawk_API?: {
      onLoad?: () => void;
      setAttributes?: (attributes: {
        name: string;
        email: string;
      }, callback: (error: any) => void) => void;
      $socket: any;
    };
  }
}
import { StyledToastContainer } from "components/atoms/Toast/Toast.styled";
import ChangelogBanner from "components/molecules/ChangelogBanner";
import { CommonModals } from "components/molecules/CommonModals";
import CookieConsent from "components/molecules/CookieConsent";
import Footer from "components/molecules/Footer";
import { Header } from "components/molecules/Header";
import { RegisterBanner } from "components/molecules/RegisterBanner";
import { TutorialProvider } from "components/organisms/TutorialProvider";
import { getUserInfo } from "lib/actions";
import { AppContext } from "lib/contexts";
import { appReducer } from "lib/reducers";
import { AppReducerActionType, UserPremiumType } from "types";
import "react-toastify/dist/ReactToastify.css";
const queryClient = new QueryClient();
const LayoutWrapper = React.memo(function LayoutWrapper({
  children
}: Readonly<{
  children: React.ReactNode;
}>) {
  const [fetched, setFetched] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [state, dispatch] = useReducer(appReducer, {
    showPricingTable: false,
    showSubscriptionTable: false,
    gold: 0,
    cookieConsentGiven: true,
    isLoggedIn: false,
    showAuthModal: false,
    userPremiumLevel: UserPremiumType.NONE,
    userFreeGens: 10,
    isOverlayVisible: false,
    username: "",
    email: "",
    referralCode: "",
    gclid: "",
    dailyLoginBonus: null
  });
  const [username, setUsername] = useState<string>("");
  const [unseenChangelogs, setUnseenChangelogs] = useState<boolean>(false);
  const loadTawkScript = () => {
    console.log("Loading Tawk.to script...");
    var s1 = document.createElement("script");
    var s0 = document.getElementsByTagName("script")[0];
    if (!s0) {
      console.error("No script tags found to insert Tawk.to");
      return; // Prevent null reference error
    }
    s1.async = true;
    s1.src = "https://embed.tawk.to/670976d12480f5b4f58c3c11/1i9ufg94e";
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode?.insertBefore(s1, s0);
    let retryCount = 0; // Track retry attempts
    const maxRetries = 10; // Set a maximum retry count

    const pollForTawkAPI = () => {
      console.log("Polling for Tawk_API...");

      // Check if Tawk_API and $socket are available
      if (window.Tawk_API && window.Tawk_API.setAttributes) {
        console.log("Tawk_API loaded successfully.");
        if (state.isLoggedIn && username && email) {
          window.Tawk_API.setAttributes({
            name: username,
            // Set the user's name
            email: email || "" // Set the user's email
          }, error => {
            if (error) {
              console.error("Error setting Tawk.to user attributes", error);
            } else {
              console.log("Tawk.to user attributes set successfully");
            }
          });
        } else if (!state.isLoggedIn) {
          // If the user is not logged in, no need to retry
          console.log("User is not logged in. No need to set Tawk.to attributes.");
        } else {
          console.warn("Username or email is missing, attributes not set. Retrying...");
          setTimeout(pollForTawkAPI, 500); // Retry if username or email is not yet available
        }
      } else {
        // Retry only up to the maxRetries limit to avoid infinite retries
        if (retryCount < maxRetries) {
          retryCount++;
          console.warn(`Tawk_API not ready. Retrying (${retryCount}/${maxRetries})...`);
          setTimeout(pollForTawkAPI, 1000); // Retry after 500ms
        } else {
          console.error("Max retries reached. Tawk.to API is not available.");
        }
      }
    };
    s1.onload = () => {
      console.log("Tawk.to script loaded successfully.");
      pollForTawkAPI(); // Start polling for Tawk_API once script loads
    };
    s1.onerror = () => {
      console.error("Failed to load Tawk.to script.");
    };
  };

  // Fetch user info
  useEffect(() => {
    (async () => {
      try {
        const {
          user
        } = await getUserInfo();
        if (user) {
          try {
            dispatch({
              type: AppReducerActionType.SET_GOLD,
              payload: {
                ...state,
                gold: user?.gold || 0
              }
            });
            setEmail(user.email || "");
            Sentry.setUser({
              email: user.email
            });
            setUsername(user.username);
            setUnseenChangelogs(user.hasUnseenChangelogs || false);
            dispatch({
              type: AppReducerActionType.SET_LOGGED_IN,
              payload: {
                ...state,
                isLoggedIn: true
              }
            });
            dispatch({
              type: AppReducerActionType.SET_USER_PREMIUM_LEVEL,
              payload: {
                ...state,
                userPremiumLevel: user.subscriptionType ?? UserPremiumType.NONE
              }
            });
            dispatch({
              type: AppReducerActionType.SET_USER_FREE_GENS,
              payload: {
                ...state,
                userFreeGens: 10 - (user.requestsInLastDay ?? 0)
              }
            });
            dispatch({
              type: AppReducerActionType.SET_USERNAME,
              payload: {
                ...state,
                username: user.username
              }
            });
            if (user.referralCode) {
              dispatch({
                type: AppReducerActionType.SET_REFERRAL_CODE,
                payload: {
                  ...state,
                  referralCode: user.referralCode
                }
              });
            }
            if (user.dailyGoldBonus) {
              dispatch({
                type: AppReducerActionType.SET_DAILY_LOGIN_BONUS,
                payload: {
                  ...state,
                  dailyLoginBonus: user.dailyGoldBonus
                }
              });
            }
          } catch (error) {
            dispatch({
              type: AppReducerActionType.SET_LOGGED_IN,
              payload: {
                ...state,
                isLoggedIn: false
              }
            });
          } finally {
            setFetched(true);
          }
        }
      } catch (e: any) {
        console.log(e);
      }
      setFetched(true);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (fetched) {
      console.log("User info fetching completed. Loading Tawk.to script...");
      loadTawkScript(); // Only load script when user info fetching is done
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetched]);
  useEffect(() => {
    document.body.style.overflow = state.isOverlayVisible ? "hidden" : "unset";
  }, [state.isOverlayVisible]);
  return <QueryClientProvider client={queryClient}>
            <AppContext.Provider value={{
      state,
      dispatch
    }}>
                <TutorialProvider>
                    <Header isLoggedIn={state.isLoggedIn} fetched={fetched} userPremiumLevel={state.userPremiumLevel} userEmail={email} username={username} />
                    <CookieConsent />
                    {!state.isLoggedIn && fetched && <RegisterBanner />}
                    {state.isLoggedIn && fetched && unseenChangelogs && <>
                            <ChangelogBanner />
                        </>}
                    {children}
                    <Footer />
                    <CommonModals email={email} />
                </TutorialProvider>
            </AppContext.Provider>
            <StyledToastContainer />
        </QueryClientProvider>;
});
export default LayoutWrapper;