/* eslint-disable indent */
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { usePathname } from "next/navigation";
import { useRouter } from "next/router";

import {
    AppReducerActionType,
    IFreeGenModelDTO,
    ImageDTO,
    IModelConfig,
    IPollGenerateImage,
    IPromptOptions,
    RequestType,
    UserPremiumType,
} from "types";

import { poll } from "./actions";
import { AppContext } from "./contexts";

export const useDetectClickOut = (initState: boolean) => {
    const triggerRef = useRef<any>(null); // optional
    const nodeRef = useRef<any>(null); // required
    const pathname = usePathname();

    const [show, setShow] = useState(initState);
    const handleClickOutside = (event: any) => {
        if (triggerRef.current && triggerRef.current.contains(event.target)) {
            return setShow(!show);
        }

        if (nodeRef.current && !nodeRef.current.contains(event.target)) {
            return setShow(false);
        }
    };
    useEffect(() => {
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    });

    useEffect(() => {
        const handleResize = () => {
            setShow(false);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });

    const handleChildButtonClick = () => {
        setShow(false);
    };

    useEffect(() => {
        if (nodeRef.current) {
            const childButton = nodeRef.current.querySelector("button");
            if (childButton) {
                childButton.addEventListener("click", handleChildButtonClick);
            }
        }
        return () => {
            if (nodeRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                const childButton = nodeRef.current.querySelector("button");
                if (childButton) {
                    childButton.removeEventListener(
                        "click",
                        handleChildButtonClick
                    );
                }
            }
        };
    }, []);

    useEffect(() => {
        setShow(false);
    }, [pathname]);

    return {
        triggerRef,
        nodeRef,
        show,
        setShow,
    };
};

export const useReferralCode = () => {
    const router = useRouter();
    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {
        const referralCode = router.query.referralCode as string;
        if (referralCode) {
            dispatch({
                type: AppReducerActionType.SET_REFERRAL_CODE,
                payload: {
                    ...state,
                    referralCode: referralCode,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query, dispatch]);
};

export const useGclid = () => {
    const router = useRouter();
    const { state, dispatch } = useContext(AppContext);

    useEffect(() => {
        const gclid = router.query.gclid as string;
        if (gclid) {
            dispatch({
                type: AppReducerActionType.SET_GCLID,
                payload: {
                    ...state,
                    gclid: gclid,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query, dispatch]);
};

type GenerateImageFunction = (
    formData: FormData,
    isPremium: boolean
) => Promise<{ requestId?: string; error?: string }>;

type PollGenerateImageFunction = (
    requestId: string,
    isPremium: boolean
) => Promise<IPollGenerateImage>;

type FormValues = {
    [key: string]: string;
};

interface UseGenerateImageOptions {
    requestType: RequestType | null;
    prompt: string | IPromptOptions | undefined;
    file?: File | null;
    charSheetUrl?: string | null;
    generateImageFunction: GenerateImageFunction;
    pollGenerateImageFunction: PollGenerateImageFunction;
    imageFile: File | null;
    selectedModel: IModelConfig | null;
    manualPrompt: boolean;
    formValues: FormValues;
    config: IFreeGenModelDTO | null;
    isPremium: boolean;
    useCustomConfig: boolean;
    useModel: boolean;
    goldCost: number;
    setError: (error: string) => void;
    setLoading: (loading: boolean) => void;
    handleSetImages: (image: string | ImageDTO[]) => void;
    includeArtStyle: boolean;
}

export const useGenerateImage = (options: UseGenerateImageOptions) => {
    const {
        requestType,
        prompt,
        file,
        charSheetUrl,
        generateImageFunction,
        pollGenerateImageFunction,
        imageFile,
        selectedModel,
        manualPrompt,
        formValues,
        config,
        isPremium,
        useCustomConfig,
        useModel,
        goldCost,
        setError,
        setLoading,
        handleSetImages,
        includeArtStyle,
    } = options;
    const { state, dispatch } = useContext(AppContext);

    const handleGenerateImage = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setError("");

            // Check if user is logged in
            if (!state.isLoggedIn) {
                e.stopPropagation();
                dispatch({
                    type: AppReducerActionType.SHOW_AUTH_MODAL,
                    payload: {
                        ...state,
                        showAuthModal: true,
                    },
                });
                return;
            }

            // Check if user has enough gold
            if (
                state.userPremiumLevel === UserPremiumType.NONE &&
                state.gold < goldCost
            ) {
                dispatch({
                    type: AppReducerActionType.SHOW_SUBSCRIPTION_TABLE,
                    payload: {
                        ...state,
                        showSubscriptionTable: true,
                    },
                });
                return;
            } else if (state.gold < goldCost) {
                dispatch({
                    type: AppReducerActionType.SHOW_PRICING_TABLE,
                    payload: {
                        ...state,
                        showPricingTable: true,
                    },
                });
                return;
            }

            // Filter form values based on manual prompt
            const filteredFormValues: FormValues = manualPrompt
                ? Object.keys(formValues)
                      .filter(
                          (
                              key
                          ): key is
                              | "Number of Images"
                              | "Size"
                              | "Negative Prompt"
                              | "Art Style" =>
                              key === "Number of Images" ||
                              key === "Size" ||
                              key === "Negative Prompt" ||
                              (key === "Art Style" && includeArtStyle)
                      )
                      .reduce((obj, key) => {
                          obj[key] = formValues[key];
                          return obj;
                      }, {} as FormValues)
                : formValues;

            // Create FormData and append necessary fields
            const formData = new FormData();

            if (file) {
                formData.append("file", file);
            }
            if (charSheetUrl) {
                formData.append("url", charSheetUrl);
            }

            if (requestType) {
                formData.append("requestType", requestType);
            }

            if (prompt) {
                if (typeof prompt === "string") {
                    formData.append("prompt", prompt);
                } else {
                    formData.append(
                        "prompt",
                        prompt.shortName || prompt.fullName
                    );
                }
            }

            if (imageFile && selectedModel?.imageToImage) {
                formData.append("referenceImage", imageFile);
            }

            if (config && !isPremium && (useCustomConfig || useModel)) {
                formData.append("model", JSON.stringify(config));
            }

            if (filteredFormValues) {
                for (const [key, value] of Object.entries(filteredFormValues)) {
                    formData.append(`requestFields[${key}]`, value);
                }
            }

            if (manualPrompt) {
                formData.append("manualPrompt", String(manualPrompt));
            }

            setLoading(true);

            // Call the generate image function
            const { requestId, error } = await generateImageFunction(
                formData,
                isPremium && !!state.gold
            );
            setError(error || "");

            if (requestId) {
                // Poll for the generated image
                const { image, error } = await poll(
                    () =>
                        pollGenerateImageFunction(
                            requestId,
                            isPremium && !!state.gold
                        ),
                    ({ image }) => !image,
                    5000
                );
                setError(error || "");
                handleSetImages(image || "");
                if (image) {
                    dispatch({
                        type: AppReducerActionType.SET_GOLD,
                        payload: {
                            ...state,
                            gold: state.gold - goldCost,
                        },
                    });
                }
            }

            setLoading(false);
        },
        [
            setError,
            state,
            goldCost,
            manualPrompt,
            formValues,
            file,
            charSheetUrl,
            requestType,
            prompt,
            imageFile,
            selectedModel?.imageToImage,
            config,
            isPremium,
            useCustomConfig,
            useModel,
            setLoading,
            generateImageFunction,
            dispatch,
            handleSetImages,
            pollGenerateImageFunction,
            includeArtStyle,
        ]
    );

    return handleGenerateImage;
};
