import React, { useContext, useEffect, useRef, useState } from "react";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { ProgressBar } from "components/atoms/ProgressBar";
import { AppContext } from "lib/contexts";
import { DailyGoldBonusDTO } from "types";
import * as Styled from "./GoldStreakBanner.styled";
export const GoldBonusBanner: React.FC = () => {
  const {
    state
  } = useContext(AppContext);
  const dailyBonusData = state.dailyLoginBonus as DailyGoldBonusDTO;
  const [message, setMessage] = useState<string>("");
  const canvasRef = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    if (state.isLoggedIn && dailyBonusData) {
      let newMessage = "";
      if (dailyBonusData.bonusAwarded && dailyBonusData.bonusAmount > 0) {
        newMessage = `🎉 You received a bonus of ${dailyBonusData.bonusAmount} gold for a ${dailyBonusData.currentStreak}-day streak!`;
      }
      if (dailyBonusData.dailyAwarded && dailyBonusData.dailyGoldAmount > 0) {
        if (newMessage) {
          newMessage += ` 💰 Additionally, you received ${dailyBonusData.dailyGoldAmount} gold for logging in today!`;
        } else {
          newMessage = `💰 You received ${dailyBonusData.dailyGoldAmount} gold for logging in today!`;
        }
      }
      if (dailyBonusData.dailyGoldAmount === 0) {
        newMessage = "⏳ You've reached the maximum of 100 gold from daily logins. Spend some gold to continue receiving daily rewards!";
      }
      if (newMessage) {
        setMessage(newMessage);
      }
    }
  }, [state.isLoggedIn, dailyBonusData]);
  useEffect(() => {
    if (dailyBonusData?.bonusAwarded) {
      // Trigger confetti animation when the banner becomes visible and a bonus was awarded
      triggerConfetti();
    }
  }, [dailyBonusData]);
  const triggerConfetti = () => {
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error("Canvas element not found");
      return;
    }
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      console.error("Unable to get canvas rendering context");
      return;
    }
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;
    const particles: Particle[] = [];
    const colors = ["#FFC700", "#FF0000", "#2E3192", "#41BBC7"];
    class Particle {
      x: number;
      y: number;
      size: number;
      speedX: number;
      speedY: number;
      color: string;
      constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
        this.size = Math.random() * 6 + 4;
        this.speedX = Math.random() * 3 - 1.5;
        this.speedY = Math.random() * 3 + 1;
        this.color = colors[Math.floor(Math.random() * colors.length)];
      }
      update() {
        this.x += this.speedX;
        this.y += this.speedY;
        this.size *= 0.99; // Shrink over time
      }
      draw(ctx: CanvasRenderingContext2D) {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fillStyle = this.color;
        ctx.fill();
      }
    }
    const createParticles = (count: number) => {
      for (let i = 0; i < count; i++) {
        particles.push(new Particle(canvas.width / 2, canvas.height / 2));
      }
    };
    const animate = () => {
      if (!ctx) return;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      particles.forEach((particle, index) => {
        particle.update();
        particle.draw(ctx);
        if (particle.size < 0.5) {
          particles.splice(index, 1);
        }
      });
      if (particles.length > 0) {
        requestAnimationFrame(animate);
      }
    };
    createParticles(100);
    animate();
  };
  if (!message) {
    return null;
  }
  const {
    bonusAwarded,
    dailyAwarded,
    dailyGoldAmount,
    bonusAmount,
    currentStreak,
    maxStreak
  } = dailyBonusData;

  // Define your bonus milestones and amounts
  const bonusMilestones = [{
    day: 7,
    amount: 10
  }, {
    day: 14,
    amount: 25
  }, {
    day: 21,
    amount: 50
  }, {
    day: 30,
    amount: 100
  }];

  // Find the next milestone
  const nextMilestone = bonusMilestones.find(milestone => milestone.day > currentStreak);
  const nextBonusIn = nextMilestone ? nextMilestone.day - currentStreak : 0;
  const nextBonusAmount = nextMilestone ? nextMilestone.amount : 0;

  // Calculate progress towards the next milestone
  const previousMilestoneDay = bonusMilestones[bonusMilestones.indexOf(nextMilestone!) - 1]?.day || 0;
  const milestoneInterval = nextMilestone ? nextMilestone.day - previousMilestoneDay : 1;
  const progressCurrent = currentStreak - previousMilestoneDay;
  const progressTotal = milestoneInterval;

  // Check if user is at gold cap
  const goldCap = 100;
  const atGoldCap = state.gold >= goldCap;
  return <Styled.BannerContainer data-sentry-element="unknown" data-sentry-component="GoldBonusBanner" data-sentry-source-file="index.tsx">
            {/* Canvas for Confetti Animation */}
            {bonusAwarded && <canvas ref={canvasRef} style={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      pointerEvents: "none",
      borderRadius: "8px",
      zIndex: 10000
    }} />}
            <Styled.BannerContent data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                <div style={{
        display: "flex",
        alignItems: "center",
        gap: "8px"
      }}>
                    <WhatshotIcon color="error" data-sentry-element="WhatshotIcon" data-sentry-source-file="index.tsx" />
                    <Styled.StreakText data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                        Current Streak: {currentStreak} days
                    </Styled.StreakText>
                </div>
                {maxStreak && <Styled.BonusText>
                        Longest Streak: {maxStreak} days
                    </Styled.BonusText>}
                <ProgressBar current={progressCurrent} total={progressTotal} data-sentry-element="ProgressBar" data-sentry-source-file="index.tsx" />
                <Styled.RewardsInfo data-sentry-element="unknown" data-sentry-source-file="index.tsx">
                    {/* Display daily gold awarded today */}
                    {dailyAwarded && dailyGoldAmount > 0 && <Styled.RewardText>
                            <MonetizationOnIcon sx={{
            color: "#FFD700"
          }} />
                            You received {dailyGoldAmount} gold today!
                        </Styled.RewardText>}
                    {/* Display bonus awarded if any */}
                    {bonusAwarded && bonusAmount > 0 && <Styled.RewardText>
                            <EmojiEventsIcon color="secondary" />
                            Bonus: {bonusAmount} gold for a {currentStreak}-day
                            streak!
                        </Styled.RewardText>}
                    {/* Display motivational message */}
                    {nextBonusIn > 0 && <Styled.MotivationalText>
                            Keep it up! Only {nextBonusIn} days to your next
                            bonus of {nextBonusAmount} gold.
                        </Styled.MotivationalText>}
                    {nextBonusIn === 0 && <Styled.MotivationalText>
                            Amazing! You&apos;ve reached the highest streak
                            bonus!
                        </Styled.MotivationalText>}
                </Styled.RewardsInfo>
                {atGoldCap && <Styled.CapMessage>
                        You&apos;ve reached the maximum of {goldCap} gold from
                        daily logins. Spend some gold to continue receiving
                        daily rewards!
                    </Styled.CapMessage>}
            </Styled.BannerContent>
        </Styled.BannerContainer>;
};